import { Spin, Modal, Row } from "antd";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Header from "../../components/Header";
import Order from "../../components/Oders";

import {
  ModalShipping,
  ModalCooking,
  ModalCookingPickup,
  ModalCancel,
  ModalPending,
  ModalReceived,
  ModalReadyToShip,
} from "../../components/Modal";

import "./index.scss";

import {
  renderButtonFunction,
  renderImageTitle,
  renderFooterReadyToShip,
  renderFooterShipping,
  renderStatus,
  renderSecondButton,
  renderModalData,
} from "./render";

import {
  apiCookingOrder,
  apiGetListOrder,
  apiReciveOrder,
  apiShipping,
  apiGetDetailOrder,
  apiBomOrder,
  apiMerchantReadyToShip,
  apiCompleteOrder,
  apiGetTurnOffAppSound,
  // apiGetListOrderRefund,
  apiGetTimeReloadOrder,
} from "../../apis/Order";

import { apiGetShipperList, apiAssignShipper } from "../../apis/Shipper";
import { customNotification } from "src/utils/CommomFunction";
import DeliveredModal from "../../components/Modal/DeliveredModal";
import ModalWaringComplete from "../../components/Modal/ModalWaringComplete";
import ChangeDeliveryStaffModal from "../../components/Modal/ChangeDeliveryStaffModal";
import { RejectOrderModal } from "../../components/Modal/RejectOrderModal";
import moment from "moment";
import { STATUS_COLUMNS } from "./columns";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import { firebaseMessaging } from "src/firebaseInit";
import { OrderTransferModal } from "../../components/Modal/OrderTransferModal";
import sound from "../../assets/notification.mp3";
import soundNotResponse from "../../assets/ordernotresponse.wav";
import sound20s from "../../assets/notification.mp4";
import { apiGetNotificationList } from "../../apis/Notification";
import NotificationComponent from "components/Notification";
import { apiAttendance } from "apis/Store";
import { statusConvertData } from "../../utils/CommomFunction";
import { isEqual } from "lodash";
import { useIdleTimer } from "react-idle-timer";
import { isJsonValid } from "../../utils/Validation/index";
import { wait } from "../../utils/CommomFunction/index";
import { isEmpty } from "lodash";

const { info } = Modal;

const HomePage = (props) => {
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shipperList, setShipperList] = useState();
  const [selectedShipper, setSelectedShipper] = useState(null);
  const [detailOrder, setDetailOrder] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingButtonTop, setLoadingButtonTop] = useState(false);
  const [updateShipper, setUpdateShipper] = useState(false);
  const [refundOrderList, setRefundOrderList] = useState([]);
  const currentModalOpen = useRef();
  const currentModalCancel = useRef();
  const currentModalOrder = useRef("");
  const loopPlayAudio = useRef();
  const loopPlayAudioNotResponse = useRef();
  const playAudio = useRef();
  const [reload, set_reload] = useState(false);

  const [isLoadingApp, setIsLoadingApp] = useState(false);

  const [turnOffAppSound, setTurnOffAppSound] = React.useState(2);
  const [orderListNotResponse, setOrderListNotResponse] = useState([]);

  const [dataOrderModal, setDataOrderModal] = useState();
  const [isShowModalPending, setShowModalPending] = useState(false);
  const [isShowModalRecieved, setShowModalRecieved] = useState(false);
  const [isShowModalReadyShip, setShowModalReadyShip] = useState(false);
  const [isShowModalShipping, setShowModalShipping] = useState(false);
  const [isShowModalCancel, setShowModalCancel] = useState(false);
  const [isShowModalCooking, setShowModalCooking] = useState(false);
  const [isShowModalRejectOrder, setShowModalRejectOrder] = useState(false);
  const [isShowModalCookingPickUp, setIsShowModalCookingPickUp] =
    useState(false);
  const [isStartedApp, setStartedApp] = useState(false);
  const [timeReloadOrder, setTimeReloadOrder] = useState(null);

  const [isRequestNoti, setRequestNoti] = useState(false);
  const [isWarningComplete, setWarningComplete] = useState(false);
  const [messageWarningComplete, setMessageWarningComplete] = useState(false);
  const [isPickup, setIsPickup] = useState(false);

  const refOrderList = React.useRef([]);
  const intervalTitle = React.useRef();
  const reloadOrderRef = React.useRef();

  const orderDetailId =
    dataOrderModal && dataOrderModal?.id ? dataOrderModal?.id : -1;
  const findOrderOffSound = orderListNotResponse.find(
    (obj) => obj?.id == orderDetailId
  );
  const isOffSound =
    findOrderOffSound && findOrderOffSound?.isOffSound ? true : false;

  const bc = new BroadcastChannel("jollibeeBroadcast");

  const checkNotificationPermission = () => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then(function (permission) {
        if (
          permission &&
          typeof permission == "string" &&
          permission == "denied"
        ) {
          if (!isRequestNoti) {
            info({
              icon: <></>,
              title: (
                <span
                  style={{ fontWeight: "bold", fontSize: 24, color: "red" }}
                >
                  Yêu cầu bật thông báo !!!
                </span>
              ),
              content: (
                <p style={{ fontWeight: "500", fontSize: 16 }}>
                  Hãy bật thông báo của trình duyệt để nhận thông báo khi có đơn
                  hàng.
                </p>
              ),
              onOk: () => {
                setRequestNoti(false);
              },
            });
            setRequestNoti(true);
          }
        }
      });
    }
  };

  useEffect(() => {
    checkNotificationPermission();
  }, []);

  const onIdle = () => {
    getOrderList();
    checkNotificationPermission();
    idleTimer.reset();
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive: () => {},
    timeout: 1000 * 60 * 9.5,
  });

  const listNotResponseSaved = localStorage.getItem("listNotResponseSaved")
    ? JSON.parse(localStorage.getItem("listNotResponseSaved"))
    : [];

  useEffect(() => {
    if (listNotResponseSaved && listNotResponseSaved.length > 0) {
      setOrderListNotResponse(listNotResponseSaved);
    }
    setStartedApp(true);
  }, []);

  const saveOrderListNotResponse = ({ order, type }) => {
    if (type == "add") {
      const index = orderListNotResponse.findIndex(
        (obj) => obj?.id == order?.id
      );
      if (index < 0) {
        const tempList = [...orderListNotResponse, order];
        setOrderListNotResponse(tempList);
      }
    } else if (type == "remove") {
      const tempList = orderListNotResponse.filter(
        (obj) => obj?.id !== order?.id
      );
      if (!isEqual(tempList, orderListNotResponse)) {
        setOrderListNotResponse(tempList);
      }
    }
  };

  const pauseOrderNotResponse = ({ order, timePause }) => {
    const indexOrder = orderListNotResponse.findIndex(
      (obj) => obj?.id == order?.id
    );
    if (indexOrder !== -1) {
      const tempList = [...orderListNotResponse];
      tempList[indexOrder] = {
        ...orderListNotResponse[indexOrder],
        isOffSound: true,
        timePause,
      };
      setOrderListNotResponse(tempList);
    }
  };

  const playOrderNOtResponseAgain = ({ order }) => {
    const indexOrder = orderListNotResponse.findIndex(
      (obj) => obj?.id == order?.id
    );
    if (indexOrder !== -1) {
      const tempList = [...orderListNotResponse];
      tempList[indexOrder] = {
        ...orderListNotResponse[indexOrder],
        isOffSound: false,
        timePause: null,
      };
      setOrderListNotResponse(tempList);
    }
  };

  function removeItemNotResponse(arr1 = [], arr2 = []) {
    return arr2.filter(
      (item2) =>
        !arr1.some((item1) => item1["order_number"] === item2["order_number"])
    );
  }

  useEffect(() => {
    if (orderListNotResponse?.length > 0) {
      let tempOrders = removeItemNotResponse(orderList, orderListNotResponse);
      let countOrderOffSound = 0;
      for (const order of tempOrders) {
        if (order?.isOffSound) {
          countOrderOffSound = countOrderOffSound + 1;
        }
      }
      if (countOrderOffSound == tempOrders?.length) {
        offSoundNotResponse();
      }
    }

    localStorage.setItem(
      "listNotResponseSaved",
      JSON.stringify(orderListNotResponse)
    );
  }, [orderListNotResponse]);

  useEffect(() => {
    getOrderList();
  }, []);

  const handleOnOffSound = () => {
    pauseOrderNotResponse({
      order: dataOrderModal,
      timePause: Date.parse(moment().format()),
    });
    if (isShowModalCooking && dataOrderModal?.status == "cooking") {
      closeModalCooking();
    }
    setTimeout(() => {
      closeAllModal();
    }, 120);
  };

  const closeAllModal = () => {
    setShowModalCancel(false);
    setShowModalCooking(false);
    setIsShowModalCookingPickUp(false);
    setShowModalPending(false);
    setShowModalReadyShip(false);
    setShowModalRecieved(false);
    setShowModalRejectOrder(false);
    setShowModalShipping(false);
    setShowModalTransfer(false);
  };

  const checkOrderInOrderList = (order) => {
    let flag = false;
    let index = orderList.findIndex((obj) => obj?.id == order?.id);
    if (index !== -1) {
      return true;
    }
    return flag;
  };

  const orginalTitle = document.title;

  function flashText() {
    const newTitle = "Có đơn mới";
    if (document.title === orginalTitle) {
      document.title = newTitle;
    } else {
      document.title = orginalTitle;
    }
  }
  const startIntervalTitle = () => {
    intervalTitle.current = setInterval(() => {
      flashText();
    }, 2000);
  };

  const stopIntervalTitle = () => {
    clearInterval(intervalTitle.current);
    intervalTitle.current = null;
    document.title = orginalTitle;
  };

  const restructOrderListNotResponse = () => {
    if (isStartedApp) {
      let tmpList = [...orderListNotResponse];
      for (const order of orderListNotResponse) {
        if (
          !checkOrderInOrderList(order) ||
          statusConvertData[order?.status] >
            parseInt(order?.notification_status)
        ) {
          tmpList = tmpList.filter((obj) => obj?.id !== order?.id);
        }
      }
      setOrderListNotResponse(tmpList);
    }
  };

  //Điểm danh hàng ngày
  useEffect(() => {
    const value = localStorage.getItem("attendance");
    if (!value) {
      localStorage.setItem("attendance", moment().format("DD/MM/YYYY"));
    } else {
      const count = moment(
        moment(value, ["DD/MM/YYYY"]).diff(moment(), "days")
      );
      if (count > 0) {
        apiAttendance().then((res) => {
          if (res.data && !res.errors) {
            localStorage.setItem("attendance", moment().format("DD/MM/YYYY"));
          }
        });
      }
    }
  }, []);

  const callApiGetTurnOffAppSound = () => {
    apiGetTurnOffAppSound().then((res) => {
      const response = res?.data?.storeConfig?.turn_off_app_sound
        ? res?.data?.storeConfig?.turn_off_app_sound
        : 2;
      setTurnOffAppSound(response);
    });
  };

  const callApiGetTimeReloadOrder = () => {
    apiGetTimeReloadOrder().then((res) => {
      const response = res?.data?.storeConfig?.time_reload_order
        ? res?.data?.storeConfig?.time_reload_order
        : 30;
      setTimeReloadOrder(response);
      clearInterval(reloadOrderRef.current);
      setTimeout(() => {
        reloadOrderRef.current = setInterval(
          () => {
            getOrderListWithoutLoading();
          },
          response ? parseInt(response) * 1000 : 30000
        );
      }, 2000);
    });
  };

  useEffect(() => {
    callApiGetTurnOffAppSound();
    callApiGetTimeReloadOrder();
  }, []);

  async function updateOrder(order) {
    if (
      order?.status == "canceled" &&
      order?.payment_method_code !== "cashondelivery"
    ) {
      // wait(2000).then(() => {
      //   getOrderListRefund();
      // });
    }

    if (order?.status == "canceled" || order?.status == "complete") {
      /* UPDATE REMOVE ORDER IN ORDER LIST UPDATE STATIUS == COMPLETE || CANCELED */
      let tempOrderList = await [...refOrderList.current];
      tempOrderList = tempOrderList?.filter?.(
        (obj) => obj?.id?.toString?.() !== order?.id?.toString?.()
      );
      setOrderList(tempOrderList);
      refOrderList.current = tempOrderList;
    } else {
      /* UPDATE STATUS ORDER of ORDERLIST */
      for (let i = 0; i < refOrderList.current?.length; i++) {
        if (refOrderList.current[i].id == order?.id) {
          let tempOrderList = await [...refOrderList.current];
          if (
            statusConvertData[order?.status] >=
            statusConvertData[tempOrderList[i].status]
          ) {
            tempOrderList[i].status = order?.status;
            refOrderList.current = tempOrderList;
            setOrderList(tempOrderList);
            return;
          }
        }
      }
    }
  }

  useEffect(() => {
    firebaseMessaging.onMessage((payload) => {
      if (payload?.notification?.title?.toString()?.includes("Đơn hàng mới")) {
        const audio = new Audio(sound);
        audio.play();
        wait(5000).then(() => {
          getOrderList();
        });
      } else {
        if (payload?.data?.order) {
          let order = payload?.data?.order;
          if (isJsonValid(payload?.data?.order)) {
            order = JSON.parse(payload?.data?.order);
          }
          wait(2000).then(() => {
            updateOrder(order);
          });
        } else {
          wait(5000).then(() => {
            getOrderList();
          });
        }
      }

      customNotification(
        "success",
        <NotificationComponent
          title={payload?.notification?.title}
          content={payload?.notification?.body}
        />,
        "notification-custom"
      );

      apiGetNotificationList({ type: "merchant" }).then((res) => {
        if (currentModalOrder?.current?.status === "pending") {
          if (
            orderList.findIndex(
              (order) =>
                order.order_number === currentModalOrder.current.order_number
            ) === -1
          ) {
            currentModalCancel.current();
          }
        }
      });

      set_reload(!reload);
    });

    return () => {
      clearInterval(loopPlayAudio.current);
      clearTimeout(playAudio.current);
    };
  }, []);

  const setReload = () => {
    apiGetListOrder().then((res) => {
      if (!res.errors && res.data) {
        setOrderList(res.data.merchantOrders.orders);
        refOrderList.current = res.data.merchantOrders.orders;
      }
    });
  };

  const playSound = () => {
    if (!document.hasFocus()) {
      startIntervalTitle();
    }
    offSoundNotResponse();
    if (loopPlayAudio.current) return;
    const audio = new Audio(sound);
    audio.play();
    loopPlayAudio.current = setInterval(() => {
      const audio = new Audio(sound);
      audio.play();
    }, 2000);
  };

  const offSound = () => {
    clearInterval(loopPlayAudio.current);
    loopPlayAudio.current = null;
  };

  const playSoundNotResponse = () => {
    const pendingOders = orderList.filter(
      (item) => item.status === "pending"
    ).length;
    if (loopPlayAudioNotResponse.current) return;
    if (pendingOders == 0) {
      const audio = new Audio(soundNotResponse);
      audio.play();
      loopPlayAudioNotResponse.current = setInterval(() => {
        const audio = new Audio(soundNotResponse);
        audio.play();
      }, 2000);
    }
  };

  const offSoundNotResponse = () => {
    clearInterval(loopPlayAudioNotResponse.current);
    loopPlayAudioNotResponse.current = null;
  };

  const checkOrderNotResponse = () => {
    let count = 0;
    const pendingOrder = orderList.filter((item) => item.status === "pending");
    for (const order of orderList) {
      console.log("order : ", order);

      const created_at = moment(order?.created_at);
      let timeCheck = order?.nontification_time || 30;
      timeCheck = timeCheck * 60;

      const now = moment();

      let distanceToDate = moment(now).diff(created_at, "seconds");
      console.log({ timeCheck, distanceToDate });

      if (
        order?.status == "received" ||
        order?.status == "cooking" ||
        order?.status == "ready_to_ship"
      ) {
        if (
          distanceToDate > timeCheck &&
          statusConvertData[order?.status] <=
            parseInt(order?.notification_status)
        ) {
          count = count + 1;
        }
      }
    }

    console.log("test");
    console.log({ count });

    if (count == 0) {
      offSoundNotResponse();
      if (pendingOrder?.length !== 0) {
        playSound();
      } else if (pendingOrder?.length == 0) {
        offSound();
      }
    }
  };

  useEffect(() => {
    clearTimeout(playAudio.current);
    playAudio.current = setTimeout(() => {
      if (orderList.filter((item) => item.status === "pending").length > 0) {
        playSound();
      } else {
        offSound();
      }
    }, 150);

    checkOrderNotResponse();

    restructOrderListNotResponse();
  }, [orderList]);

  useEffect(() => {
    apiGetShipperList().then((res) => {
      if (!res.errors && res.data) {
        setShipperList(res.data.getShipperList.list);
      }
    });
  }, [updateShipper]);

  const fetchDetailOrder = async (id) => {
    const res = await apiGetDetailOrder({ id });
    if (!res.errors && res?.data) {
      setDetailOrder(res?.data?.orderDetail);
    } else if (res?.errors?.length && res.errors[0].message) {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res?.errors[0]?.message,
      });
    }
    return res;
  };

  const openModal = async (type, order) => {
    let res;
    switch (type) {
      case "pending":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalPending();
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalPending;
          currentModalCancel.current = closeModalPending;
          currentModalOrder.current = order;
        }
        break;

      case "received":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalRecieved();
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalRecieved;
          currentModalCancel.current = closeModalRecieved;
          currentModalOrder.current = order;
        }

        break;
      case "cooking":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          if (order?.shipping_method === "Giao hàng tận nơi") {
            setSelectedShipper(null);
            setUpdateShipper(!updateShipper);
            openModalCooking();
            currentModalOpen.current = openModalCooking;
            currentModalCancel.current = closeModalCooking;
            currentModalOrder.current = order;
          } else {
            currentModalOpen.current = openModalCookingPickUp;
            currentModalCancel.current = closeModalCookingPickUp;
            currentModalOrder.current = order;
            setIsShowModalCookingPickUp(true);
          }
          setDataOrderModal(res?.data?.orderDetail);
        }

        setIsLoading(false);

        return res;
      case "ready_to_ship":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalReadyShip();
          setUpdateShipper(!updateShipper);
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalReadyShip;
          currentModalCancel.current = closeModalReadyShip;
          currentModalOrder.current = order;
        }
        break;
      case "shipping":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalShipping();
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalShipping;
          currentModalCancel.current = closeModalShipping;
          currentModalOrder.current = order;
        }

        break;
      case "arrived":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalShipping();
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalShipping;
          currentModalCancel.current = closeModalShipping;
          currentModalOrder.current = order;
        }
        break;
      case "bom_request":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalCancel();
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalCancel;
          currentModalCancel.current = closeModalCancel;
          currentModalOrder.current = order;
        }
        break;
      default:
        return;
    }
  };

  const showModalCancel = () => {
    currentModalCancel.current();
    setShowModalRejectOrder(true);
  };

  const handleSelectShipper = (item) => {
    if (item?.orders?.length >= 2) {
      return info({
        icon: <></>,
        content: <span>Shipper chỉ có thể giao 2 đơn hàng cùng lúc</span>,
        onOk() {},
        onCancel() {},
      });
    }
    setSelectedShipper(item?.id);
  };

  const renderTitleModal = () => {
    return (
      <>
        <div className="title-left">
          <h3>
            Đơn hàng #{dataOrderModal?.order_number}{" "}
            {renderStatus(dataOrderModal)}
          </h3>

          {dataOrderModal?.external_order_id && (
            <p style={{ fontWeight: "400", fontSize: 13 }}>
              {`Mã đơn ${
                dataOrderModal?.order_type == "CALL CENTER" ||
                dataOrderModal?.order_type == "STORE"
                  ? "Hoa Sao"
                  : dataOrderModal?.order_type
                      ?.toString?.()
                      ?.toLowerCase?.()
                      ?.includes?.("momo")
                  ? "momo"
                  : "zalo"
              }: `}
              #
              {dataOrderModal?.order_type == "CALL CENTER" ||
              dataOrderModal?.order_type == "STORE" ||
              dataOrderModal?.order_type
                ?.toString?.()
                ?.toLowerCase?.()
                ?.includes?.("momo")
                ? dataOrderModal?.external_order_id
                : dataOrderModal?.external_order_id
                    ?.toString()
                    ?.slice(
                      dataOrderModal?.external_order_id?.toString().length - 9
                    )}{" "}
            </p>
          )}
          <h5>
            <p>{`Nhận đơn lúc ${moment(dataOrderModal?.created_at).format(
              "hh:mm A"
            )}`}</p>
            {dataOrderModal?.assign_from?.name &&
              dataOrderModal?.status === "pending" && (
                <>
                  <span></span>
                  <b>{`Được chuyển từ ${dataOrderModal?.assign_from?.name}`}</b>
                </>
              )}
          </h5>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {renderButtonFunction(
            dataOrderModal,
            showModalCancel,
            handleOnOffSound,
            !isOffSound && findOrderOffSound
          )}
          {dataOrderModal?.status === "ready_to_ship" &&
            dataOrderModal.shipping_method === "Giao hàng tận nơi" && (
              <span
                style={{ marginLeft: "10px" }}
                key="btn2"
                onClick={openModalChangeStaff}
              >
                {renderSecondButton(dataOrderModal)}
              </span>
            )}
        </div>
      </>
    );
  };

  // =============Pending=============//
  const openModalPending = () => {
    setShowModalPending(true);
  };
  const closeModalPending = () => {
    setShowModalPending(false);
  };
  // =============Recieved=============//
  const openModalRecieved = () => {
    setShowModalRecieved(true);
  };
  const closeModalRecieved = () => {
    setShowModalRecieved(false);
  };

  // =============Ready ship =============//
  const openModalReadyShip = () => {
    setShowModalReadyShip(true);
  };
  const closeModalReadyShip = () => {
    setShowModalReadyShip(false);
  };

  // =============Shipping=============//
  const openModalShipping = () => {
    setShowModalShipping(true);
  };
  const closeModalShipping = () => {
    setShowModalShipping(false);
  };
  // =============Cancel=============//
  const openModalCancel = () => {
    setShowModalCancel(true);
  };
  const closeModalCancel = () => {
    setShowModalCancel(false);
  };
  // =============Cooking=============//
  const openModalCooking = () => {
    setShowModalCooking(true);
  };
  const closeModalCooking = () => {
    if (dataOrderModal?.id) {
      changeOrderList(dataOrderModal?.id, "cooking");
    }
    setShowModalCooking(false);
    setIsLoadingApp(false);
  };

  const openModalCookingPickUp = () => {
    setIsShowModalCookingPickUp(true);
  };

  const closeModalCookingPickUp = () => {
    setIsShowModalCookingPickUp(false);
  };

  const handleSubmitRecievedOrder = async (id) => {
    setLoadingButton(true);
    const res = await apiReciveOrder({ id });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setShowModalPending(false);
      // setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res.errors[0].message,
      });
      return false;
    }
  };

  const handleSubmitCookingOrder = async (id) => {
    setLoadingButton(true);
    const res = await apiCookingOrder({ id });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setShowModalRecieved(false);
      // setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res.errors[0].message,
      });
      return false;
    }
  };

  const handleSubmitReadyToShip = () => {
    if (!selectedShipper) {
      return info({
        icon: <></>,
        content: <span>Vui lòng chọn nhân viên giao hàng</span>,
        onOk() {},
        onCancel() {},
      });
    }
    setLoadingButton(true);
    apiAssignShipper({
      shipperId: selectedShipper,
      orderId: dataOrderModal.id,
    }).then((res) => {
      setLoadingButton(false);
      setIsLoadingApp(false);
      if (!res.errors && res.data) {
        setShowModalCooking(false);
      }
      // getOrderList();
    });
  };

  const handleSubmitShipping = async (id) => {
    setLoadingButton(true);
    const res = await apiShipping({ id });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setShowModalReadyShip(false);
      setShowModalShipping(false);
      // setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res.errors[0].message,
      });
      return false;
    }
  };

  const handleSubmitBom = (dataOrder = dataOrderModal) => {
    setLoadingButton(true);
    apiBomOrder({
      id: dataOrder.id,
    }).then((res) => {
      setLoadingButton(false);
      if (!res.errors && res.data) {
        customNotification(
          "success",
          `Đơn hàng ${dataOrder?.order_number} đã được xác nhận BOM`
        );
        setShowModalReadyShip(false);
        setShowModalShipping(false);
        closeModalCancel();
        setReload(!reload);
      }
    });
  };

  const handleSubmitOrderReadyToPickUp = async (id) => {
    setLoadingButton(true);
    const res = await apiMerchantReadyToShip({ id });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setIsShowModalCookingPickUp(false);
      // setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res.errors[0].message,
      });
      return false;
    }
  };

  const handleSubmitComplete = () => {
    setIsShowModalDelivered(true);
  };

  const [isShowModalDelivered, setIsShowModalDelivered] = useState(false);
  const openModalDelivered = () => {
    setIsShowModalDelivered(true);
  };

  const closeModalDelivered = () => {
    setIsShowModalDelivered(false);
  };

  const [isShowModalChangeStaff, setIsShowModalChangeStaff] = useState(false);
  const openModalChangeStaff = () => {
    setIsShowModalChangeStaff(true);
    currentModalCancel.current();
  };

  const closeModalChangeStaff = () => {
    // apiGetListOrder().then((res) => {
    //   if (!res.errors && res.data) {
    //     setOrderList(res.data.merchantOrders.orders);
    //     refOrderList.current = res.data.merchantOrders.orders;
    //   }
    // });
    setIsShowModalChangeStaff(false);
  };

  const timeToEnd = (dataOrderModal) => {
    if (dataOrderModal?.pickup_date && dataOrderModal?.pickup_time) {
      const time = `${moment(dataOrderModal?.pickup_date, [
        "YYYY-MM-DD HH:mm:ss",
      ]).format("DD/MM/YYYY")} ${dataOrderModal?.pickup_time}`;
      return time;
    }

    return moment(dataOrderModal?.created_at)
      .add(Number(dataOrderModal?.delivery_time), "m")
      .format("hh:mm A");
  };

  const handleSubmitCompletePickUp = () => {
    setLoadingButtonTop(true);
    apiCompleteOrder({
      id: dataOrderModal?.id,
      time: "",
    }).then((res) => {
      if (!res?.data?.completeMerchant && res?.errors) {
        setLoadingButtonTop(false);
        setMessageWarningComplete(res?.errors?.[0]?.message);
        setWarningComplete(true);
        setIsPickup(true);
        return;
      }
      if (!res.errors && res.data) {
        customNotification(
          "success",
          `Đơn hàng ${dataOrderModal?.order_number} đã hoàn thành`
        );
        setTimeout(() => {
          setLoadingButtonTop(false);
          // setReload(!reload);
          setShowModalReadyShip(false);
        }, 500);
      }
    });
  };

  const changeOrderList = (id, status) => {
    const newOrderList = orderList?.map((item) => {
      if (item?.id === id) {
        return { ...item, status };
      }
      return item;
    });
    setOrderList(newOrderList);
    refOrderList.current = newOrderList;
  };

  const updateOrderNextStatus = (orderId, nextStatus) => {
    setIsLoadingApp(true);
    const indexUpdate = orderList?.findIndex((or) => or?.id == orderId);

    if (indexUpdate == -1) return;

    let tempOrderList = [...orderList];
    tempOrderList[indexUpdate].status = nextStatus;
    setOrderList(tempOrderList);
    refOrderList.current = tempOrderList;
  };

  const getOrderListWithoutLoading = () => {
    if (
      !isShowModalCancel ||
      !isShowModalChangeStaff ||
      !isShowModalCooking ||
      !isShowModalCookingPickUp ||
      !isShowModalDelivered ||
      !isShowModalPending ||
      !isShowModalReadyShip ||
      !isShowModalRecieved ||
      !isShowModalRejectOrder ||
      !isShowModalShipping
    ) {
      apiGetListOrder().then((res) => {
        if (!res.errors && res.data) {
          setOrderList(res.data.merchantOrders.orders);
          refOrderList.current = res.data.merchantOrders.orders;
        }
      });
    }
  };

  React.useEffect(() => {
    reloadOrderRef.current = setInterval(
      () => {
        getOrderListWithoutLoading();
      },
      timeReloadOrder ? parseInt(timeReloadOrder) * 1000 : 30000
    );

    return () => {
      clearInterval(reloadOrderRef.current);
    };
  }, []);

  const getOrderList = async () => {
    setIsLoadingApp(true);
    await apiGetListOrder().then((res) => {
      if (!res.errors && res.data) {
        setOrderList(res.data.merchantOrders.orders);
        refOrderList.current = res.data.merchantOrders.orders;
      }
    });
    setIsLoadingApp(false);
  };

  // const getOrderListRefund = () => {
  //   apiGetListOrderRefund().then((res) => {
  //     if (!res.errors && res.data) {
  //       setRefundOrderList(res.data.merchantOrderHistory?.orders || []);
  //     }
  //   });
  // };

  const handleDragEnd = async ({ source, destination }) => {
    if (!destination) return;

    const orderId = source?.index;
    const nextStatus = destination?.droppableId;

    switch (source.droppableId) {
      case "pending":
        if (destination.droppableId === "received") {
          updateOrderNextStatus(orderId, nextStatus);
          const status = await handleSubmitRecievedOrder(source.index);
          setIsLoadingApp(false);
          // getOrderList();
        }
        break;

      case "received":
        if (destination.droppableId === "cooking") {
          updateOrderNextStatus(orderId, nextStatus);
          const status = await handleSubmitCookingOrder(source.index);
          setIsLoadingApp(false);
          // if (status) {
          //   getOrderList();
          // }
        }
        break;

      case "cooking":
        if (destination.droppableId === "ready_to_ship") {
          updateOrderNextStatus(orderId, nextStatus);
          const order = orderList?.find(
            (item) => item.id === Number(source.index)
          );
          if (order.shipping_method === "Giao hàng tận nơi") {
            const responseOpenModal = await openModal("cooking", order);
            if (responseOpenModal?.errors) {
              return getOrderList();
            }
            return;
          }
          const status = await handleSubmitOrderReadyToPickUp(source.index);
          setIsLoadingApp(false);
          // getOrderList();
        }
        break;

      case "ready_to_ship":
        if (destination.droppableId === "shipping") {
          const order = orderList?.find(
            (item) => item.id === Number(source.index)
          );

          if (order?.shipping_method == "Nhận tại cửa hàng") {
            return;
          }
          updateOrderNextStatus(orderId, nextStatus);
          if (order?.shipping_method === "Giao hàng tận nơi") {
            const status = await handleSubmitShipping(order?.id);
            // getOrderList();
          }
          setIsLoadingApp(false);
        }
        break;
      default:
        break;
    }
  };

  const finalDelivered = () => {
    closeModalDelivered();
    closeModalShipping();
    updateOrder({ ...dataOrderModal, status: "complete" });
  };

  const [showModalTransfer, setShowModalTransfer] = useState(false);

  useEffect(() => {
    bc.onmessage = (eventMessage) => {
      setTimeout(() => {
        getOrderList();
      }, 2000);
    };
  }, []);

  useEffect(() => {
    const visibilityChange = () => {
      if (document.hidden) {
      } else {
        stopIntervalTitle();
        getOrderList();
      }
    };

    document.addEventListener("visibilitychange", visibilityChange);
  }, []);

  const renderHeaderColumnByStatus = (item, countOrderByStatus) => {
    return (
      <div className="columns-header">
        <div className="columns-header-left">
          {renderImageTitle(item?.status)}
          <span className="columns-header-title">{item?.title}</span>
          <span className="columns-header-count">
            {`${countOrderByStatus}`}
          </span>
        </div>

        <div className="colums-header-right">
          {/* {item?.status === "received" && (
                                <>
                                  <button className="btn-print-order">
                                    In hoá đơn
                                  </button>
                                </>
                              )} */}
        </div>
      </div>
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="home-page" style={{ position: "relative" }}>
        {isLoadingApp && (
          <div className="loading_container">
            <Spin />
          </div>
        )}
        <Header reload={reload} refundOrderList={refundOrderList} />
        {orderList && !isLoading ? (
          <div className="home-board">
            <div className="container-box">
              <div className="board-wrapper">
                {STATUS_COLUMNS?.map((item, index) => {
                  const list_order = orderList
                    ?.map((order_item) => {
                      if (order_item?.status === item?.status) {
                        return order_item;
                      }
                      if (
                        order_item?.status === "bom_request" &&
                        item?.status === "shipping"
                      ) {
                        return order_item;
                      }
                      if (
                        order_item?.status === "arrived" &&
                        item?.status === "shipping"
                      ) {
                        return order_item;
                      }
                      return null;
                    })
                    .filter((item) => item);

                  const countOrderByStatus = list_order?.length ?? 0;

                  return (
                    <Droppable droppableId={item?.status} key={index}>
                      {(provided, _snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className="board-columns"
                          key={index}
                        >
                          {/******************** RENER TITLE HEADER STATUS *********************/}

                          {renderHeaderColumnByStatus(item, countOrderByStatus)}

                          {/******************** RENER LIST ORDER BY COLUMN STATUS *********************/}
                          <div className="colums-wrapper">
                            {list_order?.map((order, i) => {
                              return (
                                <Order
                                  key={order?.order_number}
                                  handleSubmitBom={handleSubmitBom}
                                  openModal={(status, order) =>
                                    openModal(status, order)
                                  }
                                  order={order}
                                  id={i}
                                  playSound={playSoundNotResponse}
                                  saveOrderListNotResponse={
                                    saveOrderListNotResponse
                                  }
                                  orderListNotResponse={orderListNotResponse}
                                  playOrderNOtResponseAgain={
                                    playOrderNOtResponseAgain
                                  }
                                  turnOffAppSound={turnOffAppSound}
                                  callApiGetTurnOffAppSound={
                                    callApiGetTurnOffAppSound
                                  }
                                />
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <Row justify="center" style={{ marginTop: 200 }}>
            <Spin spinning />
          </Row>
        )}

        {/* =====================Modal PENDING==================== */}
        <ModalPending
          isShowModalPending={isShowModalPending}
          closeModalPending={closeModalPending}
          handleSubmitRecievedOrder={async (orderId) => {
            await handleSubmitRecievedOrder(orderId);
            await updateOrderNextStatus(orderId, "received");
            setIsLoadingApp(false);
          }}
          dataOrderModal={dataOrderModal}
          loadingButton={loadingButton}
          currentModalCancel={currentModalCancel}
          currentModalOpen={currentModalOpen}
          setReload={setReload}
          reload={reload}
          handleClick={showModalCancel}
          setShowModalPending={setShowModalPending}
          setShowModalTransfer={setShowModalTransfer}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
          renderTitleModal={renderTitleModal}
        />

        <OrderTransferModal
          reload={reload}
          key="transfer"
          dataOrder={dataOrderModal}
          showModalTransfer={showModalTransfer}
          closeModalTransfer={() => {
            setShowModalTransfer(false);
          }}
          openCurrentModalOpen={() => {
            currentModalOpen.current();
          }}
          handleClose={() => {
            setShowModalPending(false);
            setReload(!reload);
          }}
        />
        {/* ===================Modal RECEIVED==================== */}

        <ModalReceived
          renderTitleModal={renderTitleModal}
          isShowModalRecieved={isShowModalRecieved}
          closeModalRecieved={closeModalRecieved}
          dataOrderModal={dataOrderModal}
          handleSubmitCookingOrder={async (orderId) => {
            handleSubmitCookingOrder(orderId);
            await updateOrderNextStatus(orderId, "cooking");
            setIsLoadingApp(false);
          }}
          loadingButton={loadingButton}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
        />

        {/* ===================Modal READY TO SHIP==================== */}
        <ModalReadyToShip
          renderTitleModal={renderTitleModal}
          isShowModalReadyShip={isShowModalReadyShip}
          closeModalReadyShip={closeModalReadyShip}
          footer={renderFooterReadyToShip(
            dataOrderModal,
            loadingButton,
            loadingButtonTop,
            handleSubmitBom,
            async (orderId) => {
              handleSubmitShipping(orderId);
              await updateOrderNextStatus(orderId, "shipping");
              setIsLoadingApp(false);
            },
            handleSubmitCompletePickUp
          )}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
          dataOrderModal={dataOrderModal}
        />

        {/* ===================Modal SHIPPING==================== */}
        <ModalShipping
          renderTitleModal={renderTitleModal}
          isShowModalShipping={isShowModalShipping}
          closeModalShipping={closeModalShipping}
          footer={renderFooterShipping(
            dataOrderModal,
            loadingButton,
            handleSubmitBom,
            handleSubmitComplete
          )}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
          dataOrderModal={dataOrderModal}
          openModalDelivered={openModalDelivered}
        />

        {/* ===================Modal CANCLE==================== */}
        <ModalCancel
          renderTitleModal={renderTitleModal}
          isShowModalCancel={isShowModalCancel}
          closeModalCancel={closeModalCancel}
          dataOrderModal={dataOrderModal}
          loadingButton={loadingButton}
          handleSubmitBom={handleSubmitBom}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
        />

        {/* ===================Modal COOKING PICK UP==================== */}
        <ModalCookingPickup
          renderTitleModal={renderTitleModal()}
          isShowModalCookingPickUp={isShowModalCookingPickUp}
          setIsShowModalCookingPickUp={setIsShowModalCookingPickUp}
          handleSubmitOrderReadyToPickUp={handleSubmitOrderReadyToPickUp}
          loadingButton={loadingButton}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
          dataOrderModal={dataOrderModal}
        />

        {/* ===================Modal COOKING==================== */}
        <ModalCooking
          title={renderTitleModal()}
          isShowModalCooking={isShowModalCooking}
          closeModalCooking={closeModalCooking}
          detailOrder={detailOrder}
          dataOrderModal={dataOrderModal}
          timeToEnd={timeToEnd}
          shipperList={shipperList}
          handleSelectShipper={handleSelectShipper}
          selectedShipper={selectedShipper}
          handleSubmitReadyToShip={async () => {
            await handleSubmitReadyToShip();
            await updateOrderNextStatus(dataOrderModal?.id, "ready_to_ship");
            setIsLoadingApp(false);
          }}
          loadingButton={loadingButton}
        />

        {/* ==================Modal DeliveredModal================ */}
        <DeliveredModal
          isShowModalDelivered={isShowModalDelivered}
          closeModalDelivered={closeModalDelivered}
          dataOrder={dataOrderModal}
          finalDelivered={finalDelivered}
          onOpenWarningComplete={(message) => {
            setMessageWarningComplete(message);
            setWarningComplete(true);
          }}
        />

        {/* ==================Modal ChangeDeliveryStaff================ */}
        <ChangeDeliveryStaffModal
          closeModalReadyShip={() => setShowModalReadyShip(false)}
          openCurrentModal={currentModalOpen.current}
          reload={() => setReload(!reload)}
          dataOrder={dataOrderModal}
          closeModalChangeStaff={closeModalChangeStaff}
          isShowModalChangeStaff={isShowModalChangeStaff}
        />

        {/* ==================Modal Reject Order================ */}
        <RejectOrderModal
          reload={() => setReload(!reload)}
          dataOrder={dataOrderModal}
          isShowModalRejectOrder={isShowModalRejectOrder}
          closeModalRejectOrder={() => setShowModalRejectOrder(false)}
          returnModal={() => currentModalOpen.current()}
          submitRejectOrder={() => {
            setShowModalRejectOrder(false);
            updateOrder({ ...dataOrderModal, status: "canceled" });
            // setReload(!reload);
          }}
        />

        <ModalWaringComplete
          isShowModal={isWarningComplete}
          closeModal={() => {
            setWarningComplete(false);
            setIsPickup(false);
          }}
          messageWarningComplete={messageWarningComplete}
          isPickup={isPickup}
        />
      </div>
    </DragDropContext>
  );
};

export default HomePage;
